import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 36,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  billTo: {
    paddingBottom: 3,
    fontFamily: "Helvetica-Oblique",
  },
  remitTo: {
    paddingBottom: 3,
    fontFamily: "Helvetica-Oblique",
  },
});

const BillTo = ({ invoice }) => (
  <View style={styles.headerContainer}>
    <View>
      <Text style={styles.billTo}>Bill To:</Text>
      {invoice.client_name &&
        invoice?.client?.preferences?.billing?.show_name_on_invoice && (
          <Text>{invoice.client_name}</Text>
        )}
      {invoice.brokerage && <Text>{invoice.brokerage?.name}</Text>}
      {invoice.brokerage?.street && (
        <Text>
          {invoice.brokerage?.street}{" "}
          {invoice.brokerage?.room ? invoice.brokerage?.room : ""}
        </Text>
      )}
      {invoice.brokerage?.city &&
      invoice.brokerage?.state &&
      invoice.brokerage?.zip ? (
        <Text>
          {invoice.brokerage?.city}, {invoice.brokerage?.state}{" "}
          {invoice.brokerage?.zip}
        </Text>
      ) : null}
    </View>
    <View>
      <View>
        <Text style={styles.remitTo}>Remit To:</Text>
        <Text>Home Snappers</Text>
        <Text>3903 Melear Dr. #153112</Text>
        <Text>Arlington, TX 76015</Text>
      </View>
    </View>
  </View>
);

export default BillTo;
