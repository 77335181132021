import React, { useState } from "react";
import {
  Select,
  Upload,
  message,
  Button,
  Row,
  Col,
  Spin,
  Typography,
} from "antd";
import { PlusOutlined, TeamOutlined } from "@ant-design/icons";

import { upload } from "../../../shared/s3Bucket/s3Service";

import { Input, Form } from "formik-antd";
import { Formik } from "formik";
import "./Brokerage.scss";

const BrokerageForm = ({ mode, initVal, clients, close, submitHandler }) => {
  const [state, setState] = useState({ loader: false, imgUrl: initVal.logo });
  const { loader } = state;

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Logo</div>
    </div>
  );
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const validateRequired = (value) => {
    const error = {};
    if (!value.name) {
      error.name = "Name is required";
    }
    if (!value.website) {
      error.website = "Website is required";
    }
    if (!value.street) {
      error.street = "Street is required";
    }
    if (!value.city) {
      error.city = "City is required";
    }
    if (!value.state) {
      error.state = "State is required";
    }
    if (!value.zip) {
      error.zip = "Zip is required";
    }
    return error;
  };

  const handleChange = (file, setFieldValue) => {
    setFieldValue("logo", file.originFileObj);
    getBase64(file.originFileObj, (imageUrl) => {
      setState({
        imgUrl: imageUrl,
        loader: false,
      });
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initVal}
      validate={validateRequired}
      onSubmit={async (values) => {
        setState({ ...state, loader: true });
        const updatedBrokerage = { ...values };
        if (typeof updatedBrokerage.logo === "object") {
          const avatar = new FormData();
          avatar.append(
            "path",
            `brokerage/logo/high/${values.name.toLowerCase()}`
          );
          avatar.append("file", updatedBrokerage.logo);
          const imagesUrl = await upload(avatar);
          updatedBrokerage.logo = imagesUrl[0];
        }
        const res = await submitHandler(updatedBrokerage);
        if (res.status === 200) {
          mode === "Add"
            ? message.success("Brokerage created successfully")
            : message.success("Brokerage updated successfully!");
        } else {
          message.error("Oops! Error occured while updating brokerage.");
        }
        setState({ ...state, loader: false });
        close(res.data);
      }}
      render={({ handleSubmit, setFieldValue, values }) => (
        <div className="form-container">
          <Spin spinning={loader}>
            <Form layout="vertical">
              <h3>
                <TeamOutlined /> Brokerage Info
              </h3>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Row gutter={[16, 16]} justify="space-around" align="middle">
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Col span={24}>
                        <Form.Item
                          label="Name"
                          name="name"
                          className="form-group"
                        >
                          <Input name="name" />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label="Website"
                          name="website"
                          className="form-group"
                        >
                          <Input addonBefore="http://" name="website" />
                        </Form.Item>
                      </Col>
                    </Col>
                    <Col
                      span={12}
                      // style={{
                      //   display: "inline-block",
                      //   position: "relative",
                      //   top: "40px",
                      //   left: "50px",
                      //   width: "100%",
                      // }}
                      justify="space-around"
                      align="middle"
                    >
                      <Row justify="space-around" align="middle">
                        <Col span={24} align="middle">
                          <Form.Item name="logo" className="form-group">
                            <Upload
                              name="logo"
                              listType="picture-card"
                              showUploadList={false}
                              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                              beforeUpload={beforeUpload}
                              onChange={({ file }) =>
                                handleChange(file, setFieldValue)
                              }
                              className="brokerageLogo"
                            >
                              {state.imgUrl ? (
                                <img
                                  src={state.imgUrl}
                                  alt="avatar"
                                  style={{ width: "100%" }}
                                />
                              ) : (
                                uploadButton
                              )}
                            </Upload>
                            <Typography.Text
                            // style={{
                            //   fontSize: "10px",
                            //   position: "relative",
                            //   right: "106px",
                            // }}
                            >
                              {values.logo ? "*Click to change your logo" : ""}
                            </Typography.Text>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="Street"
                    name="street"
                    className="form-group"
                  >
                    <Input name="street" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="Suite/Room"
                    name="room"
                    className="form-group"
                  >
                    <Input name="room" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="City" name="city" className="form-group">
                    <Input name="city" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="State" name="state" className="form-group">
                    <Input name="state" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Zip code" name="zip" className="form-group">
                    <Input type="number" name="zip" style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                {mode !== "register" ? (
                  <Col span={24}>
                    <Form.Item label="Team" name="team" className="form-group">
                      <Select
                        name="team"
                        defaultValue={initVal.team}
                        mode="multiple"
                        style={{ width: "100%" }}
                        placeholder="Select clients to create team"
                        onChange={(value) => setFieldValue("team", value)}
                      >
                        {clients
                          .filter(
                            (clt) =>
                              !clt.brokerage || clt.brokerage === initVal._id
                          )
                          .map((client) => (
                            <Select.Option key={client._id} value={client._id}>
                              {client.full}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                ) : null}
              </Row>
              <Row>
                <Col span={24}>
                  <Button
                    onClick={handleSubmit}
                    type="primary"
                    style={{ marginTop: "10px" }}
                  >
                    Save
                  </Button>
                  <Button type="link" onClick={close}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
      )}
    />
  );
};

export default BrokerageForm;
